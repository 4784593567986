import React, { Component } from "react";


import Home from "./Home";
import Foot from "./Foot"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Fab from '@mui/material/Fab';


class App extends Component {
render() {
  return (
    <div>
    <Home />
    <Foot />

    <Fab href={"#top"} color="transparent" aria-label="top" style={{position: 'fixed',bottom: 32,right: 32,}}>
        <ArrowUpwardIcon />
      </Fab>
    </div>

  );
}
}
export default App;
