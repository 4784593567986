import React, { Component } from "react";


import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import AppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import Divider from '@mui/material/Divider';

import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import BusinessIcon from '@mui/icons-material/Business';


class Foot extends Component {

  render() {


    var today = new Date();
    var year = today.getFullYear();


    return (
      <div style={{backgroundColor: '#3a3a3a'}}>
      <div style={{backgroundColor: '#3a3a3a'}}>
      <Container fixed >
      <Box>

      <Grid container spacing={2}>
        <Grid item xs={5} sm={4} md={3}>
          <h2 style={{color:"#fff", fontSize:32, fontWeight:800, whiteSpace:'nowrap',width:'100%'}}>북경의술원</h2>
        </Grid>

        <Grid xs='auto'>
        <p style={{lineHeight:3.5}}>
          <a style={{color:'#ccc',fontSize:{xs:12,sm:20}, textDecoration: 'none'}} href="https://maps.google.com/maps?ll=34.052732,-118.315435&z=16&t=m&hl=en&gl=US&mapclient=embed&cid=5099712743588162750"><BusinessIcon />3523 W Olympic Blvd #101,{<br/>}Los Angeles, CA 90019</a>

          {<br/>}
          <a style={{color:'#ccc',fontSize:{xs:12,sm:20}, textDecoration: 'none', marginRight:20,whiteSpace: 'nowrap'}} href="tel:3237338887"><PhoneIphoneIcon />(323)733-8887</a>{<br/>}
          <a style={{color:'#ccc',fontSize:{xs:12,sm:20}, textDecoration: 'none',whiteSpace: 'nowrap'}} href="mailto:Easternmhc@Gmail.com"><EmailIcon />Easternmhc@Gmail.com</a>

        </p>
        </Grid>






      </Grid>
      </Box>

      </Container>
      </div>
      <div style={{paddingTop:0,marginBottom:0,backgroundColor: '#222'}}>
      <Container fixed >


      <div style={{lineHeight:2, color:'#ccc', fontSize:12}}>
      Copyright © 2024-{year} 북경의술원. All rights reserved.
      </div>


      </Container>
      </div>
      </div>


    );
  }
}

export default Foot;
