import React, { Component } from "react";


import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import AppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import Divider from '@mui/material/Divider';

import pic9 from './files/EasternMedicine.webp';
import pic10 from './files/EasternMedicinetxt.webp';
import pic11 from './files/pi1.webp';
import pic12 from './files/gate2.webp';


import gal1 from './files/1719969142609.webp';

import gal3 from './files/1719969142637.webp';
import gal4 from './files/1719969142639.webp';
import gal5 from './files/1719969142642.webp';
import gal6 from './files/1719969142645.webp';
import gal7 from './files/1719969142647.webp';
import gal8 from './files/1719969142649.webp';
import gal9 from './files/1719969142651.webp';
import gal10 from './files/1719992795230.webp';
import gal11 from './files/1719992795235.webp';
import gal12 from './files/1719992810214.webp';
import gal13 from './files/picture.webp';



import aud1 from './files/004. 030823.mp3';
import aud2 from './files/009. 022023.mp3';
import aud3 from './files/009. 051923.mp3';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';



class Home extends Component {

  render() {




    var divStyle = {
      paddingTop: '8px',


      };
    var footerStyle = {
      backgroundColor: '#3a3a3a',
      '&:before': {
        content:"",
        backgroundColor:'#3a3a3a',
        position:'absolute',
        left:0,
        right:0,
      }
    };

    return (
      <Container fixed >
      <Box id="home" style={divStyle}>
      <Divider/>
      <AppBar component="nav" position={'relative'} color={'transparent'} elevation={0}>
        <Toolbar>

          <Typography
            variant="h1"
            component="div"
            id="top"
            sx={{ flexGrow: 1, display: { sm: 'block' }, fontSize: { xs:'1.8rem', sm: '4rem' }, fontWeight: 800, whiteSpace:'nowrap' }}
          >
            북경의술원
          </Typography>
          <Box sx={{ display: { sm: 'block' } }}>

              <Button size="small" href={'#About'} sx={{ color: '#000', fontSize: {xs:'0.6rem',sm:'1rem'}, fontWeight: 600, padding:'6px', minWidth:48 }}>
                소개
              </Button>

              <Button size="small" href={'#Gallery'} sx={{ color: '#000', fontSize: {xs:'0.6rem',sm:'1rem'}, fontWeight: 600, padding:'6px', minWidth:48 }}>
                갤러리
              </Button>

              <Button size="small" href={'#Contact'} sx={{ color: '#000', fontSize: {xs:'0.6rem',sm:'1rem'}, fontWeight: 600, padding:'6px', minWidth:48 }}>
                연락
              </Button>

          </Box>
        </Toolbar>
      </AppBar>
      <Divider style={{marginBottom:16}} />

      <Grid container spacing={2}>
        <Grid xs={12}>


          <Card variant="outlined" sx={{display:'flex', backgroundColor: "#fff", opacity:1, background:'linear-gradient(45deg,transparent 34%, #eeeee4 35%, #eeeee4 40%, transparent 41%, transparent 59%, #eeeee4  60%, #eeeee4 65%, transparent 66%),linear-gradient(135deg,transparent 34%, #eeeee4 35%, #eeeee4 40%, transparent 41%, transparent 59%, #eeeee4  60%, #eeeee4 65%, transparent 66%)', backgroundSize:'0.8em 0.8em'}}>


            <CardMedia component="img" sx={{maxWidth:'100%', height:{xs:200, sm: 300, md:600}, width:'auto', objectFit:'contain',marginTop:'auto' }} image={pic10} alt="북경의술원 Eastern Medicine Acupuncturist 한약 원장 진수님 박사"/>

            <Box sx={{ display:'flex',width:'100%', flexDirection: 'column', }}>

              <CardContent sx={{flex:'1 0 1', maxWidth:'100%', paddingLeft:0, paddingRight:0, }}>
                <Typography sx={{fontWeight:800, marginTop:{xs:'8%',sm:'8%'}, textAlign: 'center', fontSize:{xs:18,sm:40,md:60}}} component ="div" variant ="h2">
                  전통 중국 의술의 명가
                </Typography>
                <Typography sx={{fontWeight:800, marginTop:{xs:'14%',sm:'14%'}, textAlign: 'center', fontSize:{xs:18,sm:40,md:60}}} component ="div" variant ="h2">
                  북경의술원{<br/>}원장 진수림 박사
                </Typography>
                <Typography sx={{fontWeight:800, marginTop:{xs:'14%',sm:'14%'}, textAlign: 'center', fontSize:{xs:18,sm:40,md:60}}} component ="div" variant ="h2">
                  <a style={{color:'#000', textDecoration: 'none'}} href="tel:3237338887">(323)733-8887</a>
                </Typography>
              </CardContent>
            </Box>

          </Card>

        </Grid>
        <Grid xs={12}>
          <Paper elevation={0}>
          <h2 style={{fontSize:28, backgroundColor: "#76b5c5", color:"#fff"}}>1. 특진 진료</h2>
          <p style={{fontSize:20}}>
          북경의술원의 특진 진료: 불면증, 어지럼증, 중풍, 이명, 두통, 불임, 알레르기 비염, 만성기침, 위장장애, 각종 통증 전문
          </p>
          </Paper>
        </Grid>
        <Grid xs={12}>
          <Paper elevation={0}>
          <h2 id="greeting" style={{fontSize:28, backgroundColor: "#76b5c5", color:"#fff"}}>2. 인사말씀</h2>
          <Card sx={{display:'flex', marginLeft:'auto', marginRight:'auto', width:'80%', backgroundColor: "#eeeee4", borderTop: '8px solid #76b5c5', borderBottom: '8px solid #76b5c5'}}>
            <Box sx={{ display:'flex', flexDirection: 'column' }}>

              <CardContent sx={{flex:'1 0 1', minWidth:'80%', }}>
                <Typography sx={{ paddingTop:'2%', fontSize:{xs:18,sm:24}}} component ="div" variant ="p">
                  요즘 TV를 보면 치매에 걸리기 전에 미리미리 대비하여 보험을 드시 라고 하시는데, 저는 그것보다 더 중요한것은 치매나 중풍같은 큰병에 걸리기전에 미리 건강관리를 하시는것이 중요하다고 말씀드리고 싶습니다.
                </Typography>
                <Typography sx={{ paddingTop:'2%', fontSize:{xs:18,sm:24}}} component ="div" variant ="p">
                  치매에 걸리고 보험에 들면 무엇 하시겠습니까? 양로병원에도 최대한 늦게가고 싶은것이 우리 모두의 바램입니다. 젊은 사람들은 아직 실감 이 나지 않을뿐입니다. 평생을 품위있고 근면하게 살아오셨는데 행복 하고 편안한 노년생활을 살아가기 위하여 병은 예방하고, 큰병이 오기 전에 치료하시기 바랍니다!
                </Typography>
                <Typography sx={{ paddingTop:'2%', fontSize:{xs:18,sm:24}}} component ="div" variant ="p">
                  건강은 건강할때 지켜야 한다. 백번을 되새김해도 좋은 말씀입니다.{<br/>}모든 분들의 건강을 기원드립니다
                </Typography>

              </CardContent>
            </Box>
            <CardMedia component="img" sx={{width:300, height:'auto', objectFit:'contain', display:{xs:'none',md:'block'},marginTop:'auto'}} image={pic9} alt="북경의술원 Eastern Medicine Acupuncturist 한약 원장 진수님 박사"/>
          </Card>

          </Paper>
        </Grid>
        <Grid xs={12}>
          <Paper elevation={0}>
          <h2 style={{fontSize:28, backgroundColor: "#76b5c5", color:"#fff"}}>3. 라디오 칼럼</h2>
          <p style={{fontSize:20}}>
          많은 분들의 존경과 사랑속에서 라디오 코리아 1년, 현재는 라디오 서울 1650AM 11년째 3분 칼럼 진행중입니다.  9:55 AM & 7:55 PM
          </p>
          <h3 style={{fontSize:20, fontWeight:600}}>1)중풍에 대하여</h3>
          <audio style={{width:"100%"}} controls>
            <source src={aud3} type="audio/mpeg"/>
          Your browser does not support the audio element.
          </audio>
          <h3 style={{fontSize:20, fontWeight:600}}>2) 불면증에 대하여</h3>
          <audio style={{width:"100%"}} controls>
            <source src={aud2} type="audio/mpeg"/>
          Your browser does not support the audio element.
          </audio>
          <h3 style={{fontSize:20, fontWeight:600}}>3)이명에 대하여</h3>
          <audio style={{width:"100%"}} controls>
            <source src={aud1} type="audio/mpeg"/>
          Your browser does not support the audio element.
          </audio>
          </Paper>
        </Grid>
        <Grid xs={12}>
          <Paper elevation={0}>
          <h2 id={"About"} style={{fontSize:28, backgroundColor: "#76b5c5", color:"#fff"}}>4. 북경의술원 소개</h2>
          <Card sx={{marginLeft:'auto', marginRight:'auto', width:{xs:'100%',sm:'90%'}, backgroundColor: "#E5ECF4", borderTop: '8px solid #76b5c5', borderBottom: '8px solid #76b5c5'}}>

              <CardContent sx={{minWidth:'80%', }}>
                <Grid container >
                  <Grid xs={12}>
                  <Typography sx={{ color:'#fff', fontSize:{xs:28,sm:40},  fontWeight:700, textAlign:'center'}} component ="div" variant ="h2">
                    <div style={{backgroundColor: "#76b5c5", width:'100%'}}>북경의술원 소개</div>
                  </Typography>
                  </Grid>
                  <Grid style={{paddingTop:12}} xs={4} sm={4}>
                  <Typography sx={{ color:'#fff', fontSize:{xs:18,sm:24,md:32},  fontWeight:700, textAlign:'left', textIndent:{xs:2, sm:12, md:24}}} component ="div" variant ="h2">
                    <div style={{backgroundColor: "#76b5c5", width:'100%'}}>1. 원장</div>
                  </Typography>
                  </Grid>
                  <Grid style={{paddingTop:12}} xs={0} sm={1}/>
                  <Grid style={{paddingTop:12}} xs={8} sm={7}>
                  <Typography sx={{ fontSize:{xs:18,sm:24,md:32},  fontWeight:700,}} component ="div" variant ="p">
                    진 수림 박사
                  </Typography>
                  </Grid>

                  <Grid style={{paddingTop:12}} xs={4} sm={4}>
                  <Typography sx={{ color:'#fff', fontSize:{xs:18,sm:24,md:32},  fontWeight:700, textAlign:'left', textIndent:{xs:2, sm:12, md:24}}} component ="div" variant ="h2">
                    <div style={{backgroundColor: "#76b5c5", width:'100%'}}>2. 전화번호</div>
                  </Typography>
                  </Grid>
                  <Grid style={{paddingTop:12}} xs={0} sm={1}/>
                  <Grid style={{paddingTop:12}} xs={8} sm={7}>
                  <Typography sx={{ fontSize:{xs:18,sm:24,md:32},  fontWeight:700,}} component ="div" variant ="p">
                    <a style={{color:'#000', textDecoration: 'none', fontWeight:700}} href="tel:3237338887">(323)733-8887</a>
                  </Typography>
                  </Grid>

                  <Grid style={{paddingTop:12}} xs={4} sm={4}>
                  <Typography sx={{ color:'#fff', fontSize:{xs:18,sm:24,md:32},  fontWeight:700, textAlign:'left', textIndent:{xs:2, sm:12, md:24}}} component ="div" variant ="h2">
                    <div style={{backgroundColor: "#76b5c5", width:'100%'}}>3. 진료시간</div>
                  </Typography>
                  </Grid>
                  <Grid style={{paddingTop:12}} xs={0} sm={1}/>
                  <Grid style={{paddingTop:12}} xs={8} sm={7}>
                  <Typography sx={{ fontSize:{xs:18,sm:24,md:32},  fontWeight:700,}} component ="div" variant ="p">
                    월/화/목/금 9:30AM - 4:30PM{<br/>}수/토/일 - Closed{<br/>}{<br/>}
                    Mon/Tues/Thurs/Fri 9:30AM - 4:30PM{<br/>}Wed/Sat/Sun - Closed
                  </Typography>
                  </Grid>

                  <Grid style={{paddingTop:12}} xs={4} sm={4}>
                  <Typography sx={{ color:'#fff', fontSize:{xs:18,sm:24,md:32},  fontWeight:700, textAlign:'left', textIndent:{xs:2, sm:12, md:24}}} component ="div" variant ="h2">
                    <div style={{backgroundColor: "#76b5c5", width:'100%'}}>4. 주소</div>
                  </Typography>
                  </Grid>
                  <Grid style={{paddingTop:12}} xs={0} sm={1}/>
                  <Grid style={{paddingTop:12}} xs={8} sm={7}>
                  <Typography sx={{ fontSize:{xs:18,sm:24,md:32},  fontWeight:700,}} component ="div" variant ="p">
                    <a style={{color:'rgba(0, 0, 0, 0.87)',textDecoration: 'none'}} href="https://maps.google.com/maps?ll=34.052732,-118.315435&z=16&t=m&hl=en&gl=US&mapclient=embed&cid=5099712743588162750">3523 W Olympic Blvd #101,{<br/>}Los Angeles, CA 90019{<br/>}(Olympic과 Wilton코너 정병원 옆)</a>

                  </Typography>
                  </Grid>
                </Grid>


              </CardContent>

            <CardMedia component="img" sx={{margin:'auto', display: 'block', maxWidth:'100%', objectFit:'contain'}} image={pic12} alt="북경의술원 Eastern Medicine Acupuncturist 한약 원장 진수님 박사 gate"/>
          </Card>

          </Paper>
        </Grid>
        <Grid xs={12}>
          <Paper elevation={0}>
          <h2 id="특진 진료" style={{fontSize:28, backgroundColor: "#76b5c5", color:"#fff"}}>5. 특진 진료</h2>
          <p style={{fontSize:20}}>
          한약에도 명품이 있습니다.
          </p>
          <p style={{fontSize:20}}>
          생약의 명문 맞춤형 한약의 전문 북경의술원.
          </p>
          <p style={{fontSize:20}}>
          정확한 진단과 원인치료로 여러분들의 건강을 책임집니다.
          </p>
          <Card style={{margin:'auto', width:'fit-content'}}>
          <img alt='전통 중국 의술로 정확하게 병의 원인을 진단하고, 침술과 한약으로 치료합니다' style={{ display: 'block', maxWidth:"100%", height:"auto"}}
          src={gal13}>
          </img>
          </Card>
          <p style={{fontSize:20}}>
          진 수림 박사님의 건강칼럼중에서 간단하게 추려서 말씀드립니다.
          </p>
          <h3 style={{fontSize:20, fontWeight:600}}>1)위장질환</h3>
          <p style={{fontSize:20}}>
          옛부터 동양의학에서는 위장은 &#60;&#60;정신이 기본&#62;&#62;이라고 하였습니다. 그만큼 정신과 밀접하기 때문에 현대에서도 스트레스를 받으면 바로 소화가 안되기도 합니다.동양의술은 속병을 먼저 고치는 것을 우선으로 하기 때문에 우리 몸에 중심이 되는 위장~소화계를 잘 다스리는 것을 무엇보다 중요하게 여겨  옛부터 	&#60;&#60;脾胃 비위파&#62;&#62;가 형성되기도 하였습니다.
          </p>
          <p style={{fontSize:20}}>
          현재 서양의학에서 주로 사용하는 위장의 양약들은 H2 (블락커)가 있는데 이것은 히스타민의 자극을 차단하고 신호를 감지하는 장치  즉 리셉터를  방해하여 위액의 생성을 막는 약입니다. 제약회사가 쓰는 지네릭 이름으로  Zantac (잰택) 그리고 Tagamet (태가메트), Axid (액시드) 등이 있고 또 위액이 생산과정 마지막단계를 차단하는 약들로는 Prilosec (플릴로섹) Protinix (프로티닉스 ) Nexium (넥시움 ) 등이 있습니다.
          </p>
          <p style={{fontSize:20}}>
          북경의술원을 방문하시는 위장질환 환자분들을 보면 최근 몇년 동안은 Omeprazole (오메프라졸)을 많이 드십니다. 이 양약은 60일이상 복용하면 치매의 발병률이 높기 때문에 의사선생님의 정확한 지시에 따르셔야 합니다. 몇년을 복용하셨다는 환자분들도 계십니다.
          </p>
          <p style={{fontSize:20}}>
          위장질환은 북경의술원의 특진질진료로 한약 한두제를 드시고도 그렇게 고생하시던 각종 위장질환에서 해방되어 커피도 드시고 항상 씻어드시던 김치도 그냥 드시고 모두 위장이 편하니 살맛난다 하시면서 행복해 하십니다.
          </p>
          <p style={{fontSize:20, fontWeight:600}}>
          [북경의술원 원장 진수림 박사]
          </p>

          <h3 style={{fontSize:20, fontWeight:600}}>2)만성기침</h3>

          <p style={{fontSize:20}}>
          기침은 소모성 질환이기 때문에 환자분들은 피로에 시달리고 생활의 질이 떨어집니다. 독감을 앓으셨거나 코로나 휴우증 이후 기침으로 북경의술원을 내원하신 환자분들은 치료를 받으시고 이렇게 빨리 기침이 나을수 있는데 그동안 기침에 좋다는 이런저런 건강식풍을 드시면서 병을 키우셨다고 합니다.
          </p>
          <p style={{fontSize:20}}>
          양방학적으로 보면 우리가 기침을 하는 것은 바로 기도에 쌓인 이물질을 스스로 없애려는 인체의 생리적 반응이자 수단입니다. 우리가 감기나 코로나와 같은 바이러스에 감염되면 그것이 어떠한 감염이든 기관지에 있는 세포속에서는 감염이나 자극에 대한 반응으로 평소보다 더 많은 점액이 만들어집니다. 그러면 우리는 기침을 하여 점액~바로 그 이물질을 뱉어내게 됩니다. 뱉어내는 것을 민간에서는 가래라고 하고 한방에서는 &#60;&#60;담&#62;&#62;이라고 합니다.
          </p>
          <p style={{fontSize:20}}>
          동양의학은  &#60;&#60;肺主出氣 &#62;&#62;폐주출기 즉 내쉬는 숨은 폐가 주관하고 &#60;&#60;腎主納氣&#62;&#62;신주납기 즉 들숨은 신장이 주관합니다. 우리 몸에서 기의 근본은 신장이기 때문에 신장을 보하고 또 비장이 약하여 담이 축적되는 환자분들한테는 비장도 보하면서 담의 생성을 막고 기도를 이완시키면서 치료를 합니다. 환자마다 병의 발병 원인, 병이 발생한 주요 장기가 모두 다를 수 있기 때문에 사람마다 맞춤형 한약으로 &#60;&#60;원인치료&#62;&#62;하면 병이 재발이 없고 이전보다 더 건강한 생활를 하십니다.
          </p>
          <p style={{fontSize:20, fontWeight:600}}>
          [북경의술원 원장 진수림 박사]
          </p>

          <h3 style={{fontSize:20, fontWeight:600}}>3)어지러움증</h3>

          <p style={{fontSize:20}}>
          양방에서는 어지러움증을 청각기관인 달팽이관, 귀속에 있는 전정기관, 크게는 말초성 어지러운증과 중추성 어지러운증으로  나눕니다. 동양의술은 어지러움증을 간, 담음, 허한 것과 밀접한 연관이 있다고 보고 있습니다.
          </p>
          <p style={{fontSize:20}}>
          예로, 간하고 연관이 있는 어지러움증은 동양의학이나 양방 의학은 모두 피가 부족함을 의미합니다. 임상에서 어지러운 환자분들을 보면 귀애서 소리가 나고, 입이 쓰고, 과로하거나 피곤하면 증상이 더 심해진다고 호소합니다. 이런 환자분들을 진맥을 해보면 현맥과 삭맥이 잡히고 혀를 쑤~욱 내밀어보세요 하면서 설태를 보면 혓바닥에 황태가 엷게 끼여있음을 볼 수 있습니다. 또 환자분들은 입이 마르고 눈이 쉽게 충혈되고 가슴이 답답하거나 쉽게 체하고 소화가 잘안되어서 조심하신다는 분들도 계십니다.
          </p>
          <p style={{fontSize:20}}>
          북경의술원에서는 이런 간하고 연관이 있는 어지러증이 있는 환자분들께는 &#60;&#60;천마구등음&#62;&#62;에 다른 10여가지의 질좋은 약재를 더 가감하여 치료를 해드립니다. &#60;&#60;천마구등음&#62;&#62; 이 방제는 두통, 어지러움증, 불면증 그리고 양방에서 혈압약을 드셔도 혈압이 잘 조절이 안되시는 널뛰기 고혈압에도 정확히 진단 후 사용하면 좋은 효과를 보실 수 있습니다. 동양의술은 이렇게 방제 하나를 가지고도 의사의 폭넓은 의학 지식과 풍부한 임상경험 특히 약재의 산지, 귀경, 효능 그리고 약재의 현대 약리작용과 환자분이 현재 복용하고 있는 양약과의 상호작용을 잘 파악하고 있으면 여러병을 잘 고칠 수 있습니다. 모든 분들이 건강하시길 바랍니다.
          </p>
          <p style={{fontSize:20, fontWeight:600}}>
          [북경의술원 원장 진수림 박사]
          </p>

          <h3 style={{fontSize:20, fontWeight:600}}>3)알레르기 비염</h3>

          <p style={{fontSize:20}}>
          알레르기에 침치료는 아주 좋은 효과가 있습니다. 알레르기 비염때문에 고생하시던 많은 환자분들은 우리 북경의술원에 오셔서 이런 말씀을 하십니다. 예전에 비염때문에 차이나타운에 있는 한 한의원에 가서 침을 맞으시면 그때 당시는 몇번 맞으면 좋아져셨는데 그 다음해에는 침을 맞아도 더이상 효과가 없으셨다고 합니다. 다른 여러 원인도 있으 수 있겠지만 코로나 바이러스도 자꾸 변종이 생기고 변질이 되는 것처럼 알레르기겐도 그 사람의 건강상태에 따라서 해마다 다른 반응를 보일 수도 있습니다.
          </p>
          <p style={{fontSize:20}}>
          한번은 우스운 실화가 있습니다. 60대 후반의 남자환자분이 비염때문에 북경의술원을 방문하셨는데 침치료 때마다 꼭 부인이 치료실에 따라 들어오십니다.침을 다놓고 &#60;&#60;방이 답답한데 대기실에서 기다리실까요?&#62;&#62; 하면 &#60;&#60;괜찮습니다. 그냥 있을게요&#62;&#62; 하시면서 부인은 다른 한의사선생님이 들어가서 침을 뽑을 때가지 남편 옆을 지키고 계십니다.
          </p>
          <p style={{fontSize:20}}>
          3주 정도 지나서 비염이 치료가 되고 &#60;&#60;이제 졸업하셔도 되십니다&#62;&#62; 하셨더니 그때야 부인이 &#60;&#60;졸업장은 없나요?&#62;&#62; 웃으시면서 말씀하시기를 남편이 첫날 침을 맞고 코막힘도 깜쪽같이 없어지고 콧물도 많이 줄여드니 신기하기도 하고 한편으로는 수상하기도 하여 침치료 때마다 치료실에서 남편 옆을 지키셨다고 합니다...
          </p>
          <p style={{fontSize:20}}>
          알레르기 비염은 북경의술원의 특진진료입니다.
          </p>
          <p style={{fontSize:20, fontWeight:600}}>
          [북경의술원 원장 진수림 박사]
          </p>

          </Paper>
        </Grid>
        <Grid xs={12}>
          <Paper elevation={0}>
          <h2 id="강점" style={{fontSize:28, backgroundColor: "#76b5c5", color:"#fff"}}>6. 북경의술원 강점</h2>
          <Card style={{margin:'auto',width:'fit-content'}}>
          <img alt='효과가 좋은 탕약이 탄생하려면 첫번때 정확한 진단과, 둘째는 한약처방전, 셋째는 좋은약재의 선별, 넷째는 한약의 법제와 한약의 달이는 과정이 중요합니다.' style={{ display: 'block', maxWidth:"100%", height:"auto"}}
          src={pic11}>
          </img>
          </Card>
          <p style={{fontSize:20}}>
          효과가 좋은 탕약이 탄생하려면 첫번때 정확한 진단과, 둘째는 한약처방전, 셋째는 좋은약재의 선별, 넷째는 한약의 법제와 한약의 달이는 과정이 중요합니다. 이렇게 네박자가 잘 맞아야만 효과가 으뜸인 한약이 탄생을 합니다. 북경의술원에서는 약 한재 한재 모두 환자 맞춤형으로 장인의 정신과 전통방법으로 다립니다.
          </p>
          <p style={{fontSize:20}}>
          북경의술원은 약 달이는 과정을 모두 보실수있게 탕약 제조실에 큰 유리창문이 있습니다. 요즘 많은 고급식당들도 요리하는 과정을 손님들이 보실수있도록 주방을 오픈하고 경영하십니다. 한약도 음식입니다. 깨끗하고 철저한 위생과 여러가지 규칙이 지켜져야 합니다. 진료하러 오셨다가 기다리시는 동안에 환자분들은 한약을 달이는 과정을 한약제조실 유리창 너머로 지켜보시면서 "정말 정성이 많이 들어갑니다" 하시면서 엄지척을 해 보이시는 분들도 계십니다.
          </p>
          <p style={{fontSize:20}}>
          북경의술원은 한약을 환자분이 픽업하는 당일에 달여서 드리기 때문에 금방 다려서 나온 따끈따뜬한 한약을 가져 가십니다. 환자분이 약 픽업을 오시면 앞에서 일하시는 한의사 선생님이 약 한봉지를 컵에 담아 드립니다. 그러면 모든 환자분들의 첫말씀이{<br/>}"와우~~약이 너무 진하다!" 고 하십니다. 북경의술원은 한약 한제에 보통 한의원의 3배 이상으로 햔약재가 많이 들어가고, 한약재값의 대폭 상승으로 힘들지만 환자분이 병치료가 우선이기 때문에 절대 약 재료를 아끼지 않습니다. 언제나 고품질 믿을 수 있는 한약재만 사용합니다.
          </p>
          <p style={{fontSize:20}}>
          양약을 드시는분들도 많으신데 양약과 한약의 상호관계도 잘 이해를 하여야하며, 한의사지만 양방지식을 어느정도 갖추고 있어야합니다. 한약은 과학적으로 지어져야 합니다. 저는 한약을 처방할때 한의학적 이론이 뒷받침되고, 그 이론이 성립되고, 과학적인 실험결과와 임상효과가 입증되고, 안전성이 확보된 약재로 한약 처방전을 내립니다.{<br/>}&#60;&#60;先爱治患&#62;&#62; 먼저 환자를 사랑하고 치료에 임하라. 이제 60을 바라보는 나이가 되었지만 저는 언제나 아버지와 지도교수들의 가르침을 가슴깊이 새기고 환자 한분 한분을 사랑의 마음을 담아 진료에 임하며 부모님께 드리는 마음으로 혼신을 다하여 약을 제조합니다.
          </p>


          </Paper>
        </Grid>

        <Grid xs={12}>
          <Paper elevation={0}>
          <h2 id="Gallery" style={{fontSize:28, backgroundColor: "#76b5c5", color:"#fff"}}>7. 포토 갤러리</h2>
          <Card style={{marginLeft:'auto',marginRight:'auto', display: 'block', maxWidth:"80%", height:"auto", border: '6px solid #E5ECF4', marginBottom:12}}>
          <img alt='Clinic Picture' style={{display: 'block',  width:"100%", objectFit:'contain', }}
          src={gal1}>
          </img>
          </Card>


          <Card style={{marginLeft:'auto',marginRight:'auto', display: 'block', maxWidth:"80%", height:"auto", border: '6px solid #E5ECF4', marginBottom:12}}>
          <img alt='Clinic Picture' style={{display: 'block',  width:"100%", objectFit:'contain', }}
          src={gal3}>
          </img>
          </Card>
          <Card style={{marginLeft:'auto',marginRight:'auto', display: 'block', maxWidth:"80%", height:"auto", border: '6px solid #E5ECF4', marginBottom:12}}>
          <img alt='Clinic Picture' style={{display: 'block',  width:"100%", objectFit:'contain', }}
          src={gal4}>
          </img>
          </Card>
          <Card style={{marginLeft:'auto',marginRight:'auto', display: 'block', maxWidth:"80%", height:"auto", border: '6px solid #E5ECF4', marginBottom:12}}>
          <img alt='Clinic Picture' style={{display: 'block',  width:"100%", objectFit:'contain', }}
          src={gal5}>
          </img>
          </Card>
          <Card style={{marginLeft:'auto',marginRight:'auto', display: 'block', maxWidth:"80%", height:"auto", border: '6px solid #E5ECF4', marginBottom:12}}>
          <img alt='Clinic Picture' style={{display: 'block',  width:"100%", objectFit:'contain', }}
          src={gal6}>
          </img>
          </Card>
          <Card style={{marginLeft:'auto',marginRight:'auto', display: 'block', maxWidth:"80%", height:"auto", border: '6px solid #E5ECF4', marginBottom:12}}>
          <img alt='Clinic Picture' style={{display: 'block',  width:"100%", objectFit:'contain', }}
          src={gal7}>
          </img>
          </Card>
          <Card style={{marginLeft:'auto',marginRight:'auto', display: 'block', maxWidth:"80%", height:"auto", border: '6px solid #E5ECF4', marginBottom:12}}>
          <img alt='Clinic Picture' style={{display: 'block',  width:"100%", objectFit:'contain', }}
          src={gal8}>
          </img>
          </Card>
          <Card style={{marginLeft:'auto',marginRight:'auto', display: 'block', maxWidth:"80%", height:"auto", border: '6px solid #E5ECF4', marginBottom:12}}>
          <img alt='Clinic Picture' style={{display: 'block',  width:"100%", objectFit:'contain', }}
          src={gal9}>
          </img>
          </Card>

          <Card style={{marginLeft:'auto',position: 'relative',marginRight:'auto', display: 'block', maxWidth:"80%", height:"auto", border: '6px solid #E5ECF4', marginBottom:12}}>
            <CardMedia component="img" sx={{display: 'block',  width:"100%", objectFit:'contain', textAlign:'center',}} image={gal10} alt="북경의술원 Eastern Medicine Acupuncturist 한약 원장 진수님 박사 gate"/>
            <CardMedia component="img" sx={{display: 'block',  width:"100%", objectFit:'contain', textAlign:'center',}} image={gal11} alt="북경의술원 Eastern Medicine Acupuncturist 한약 원장 진수님 박사 gate"/>
            <CardContent sx={{ position:'absolute', top:'50%', width:'100%', marginTop:{xs:-4.5, sm:-5.5, md:-6.6, lg:-8.4}, marginLeft:'-16px'}}>
              <Typography
                variant="h1"
                component="div"
                id="top"
                sx={{  backgroundColor:'rgba(118,181,197,0.6)', flexGrow: 1, display: { sm: 'block' }, fontSize: { xs:'1rem', sm: '1.4rem', md:'2rem', lg: '2.8rem' }, fontWeight: 800, whiteSpace:'nowrap', color:'#fff', textAlign:'center', }}
              >
                한사람 한사람 맞춤형으로 북경의술원 탕약{<br/>}
                제조실에서 직접 한약을 제조해드립니다
              </Typography>
            </CardContent>
          </Card>



          <Card style={{marginLeft:'auto',marginRight:'auto', display: 'block', maxWidth:"80%", height:"auto", border: '6px solid #E5ECF4', marginBottom:12}}>
          <img alt='Clinic Picture' style={{display: 'block',  width:"100%", objectFit:'contain', }}
          src={gal12}>
          </img>
          </Card>



          </Paper>
        </Grid>

        <Grid xs={12}>
          <Paper elevation={0}>
          <h2 id={"Contact"}style={{fontSize:28, backgroundColor: "#76b5c5", color:"#fff"}}>8. 예약 전화</h2>
          <p style={{fontSize:24, color:"#76b5c5"}}>
          번호를 탭하시면 자동 전화{<br/>}
          <a style={{color:'#000', textDecoration: 'none'}} href="tel:3237338887"><PhoneIphoneIcon />(323)733-8887</a>

          </p>
          </Paper>
        </Grid>


        <Grid xs={12}>
          <Paper elevation={0}>
          <h2 style={{fontSize:28, backgroundColor: "#76b5c5", color:"#fff"}}>9. 오시는 길</h2>
          <p style={{fontSize:24, color:"#76b5c5"}}>
          <a style={{fontSize:24, color:"#76b5c5",textDecoration: 'none'}} href="https://maps.google.com/maps?ll=34.052732,-118.315435&z=16&t=m&hl=en&gl=US&mapclient=embed&cid=5099712743588162750">3523 W Olympic Blvd #101,{<br/>}Los Angeles, CA 90019{<br/>}(Olympic과 Wilton코너 정병원 옆)</a>

          </p>
          <Paper style={{margin:'auto', marginBottom:12, maxWidth:600, }}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.6543407136223!2d-118.31801002286481!3d34.052736817701806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2b8890ade7b27%3A0x46c5d1b88a1aecbe!2sEastern%20Medicine%20Health%20Clinic!5e0!3m2!1sen!2sus!4v1720739124584!5m2!1sen!2sus" width="100%" height="450" style={{border:0, margin:'auto', display: 'block', maxWidth:600}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </Paper>
          </Paper>
        </Grid>
        <Divider  />




      </Grid>
      </Box>

      </Container>


    );
  }
}

export default Home;
